import React, { useMemo } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import TablePlaceholder from '../../../../../../../common/components/PageRelated/Body/components/Table/components/TablePlaceholder';
import { useWeeklyPlanningPageContext } from '../../../../../../context';
import Group from './components/Group';
import { noContent } from './placeholders';
import { useDragAndDrop } from './hooks/useDragAndDrop';
import { Container, Table, TableHeader, ScrollableArea, TableBody } from './styles';
export const AdvancedViewModeTable = () => {
    const content = useWeeklyPlanningPageContext((store) => store.mainTable.advancedViewMode.filteredContent);
    const showDisciplineColumn = useWeeklyPlanningPageContext((store) => store.curriculumComponents.selectedDisciplines?.length > 1);
    const plannings = useMemo(() => {
        return content.flatMap((group) => group.plannings);
    }, [content]);
    const dragAndDropRelated = useDragAndDrop();
    if (plannings.length === 0) {
        return React.createElement(TablePlaceholder, { content: noContent });
    }
    return (React.createElement(DragDropContext, { onDragEnd: dragAndDropRelated.onDragEnd },
        React.createElement(Container, null,
            React.createElement(ScrollableArea, null,
                React.createElement(Table, { "$showDisciplineColumn": showDisciplineColumn },
                    React.createElement(TableHeader, { "$showDisciplineColumn": showDisciplineColumn },
                        showDisciplineColumn ? React.createElement("div", null, "Componente Curricular") : null,
                        React.createElement("div", null, "UT"),
                        React.createElement("div", null, "Habilidade"),
                        React.createElement("div", null, "Objetivos de Aprendizagem"),
                        React.createElement("div", null, "Objetos de Conhecimento"),
                        React.createElement("div", null, "Sequ\u00EAncia Did\u00E1tica"),
                        React.createElement("div", null, "Coment\u00E1rios")),
                    React.createElement(Droppable, { droppableId: "advanced-view-mode-table" }, (provided) => (React.createElement(TableBody, { ref: provided.innerRef, ...provided.droppableProps },
                        content.map((group, index) => (React.createElement(Group, { key: `group-${group.number}-${group.plannings.length}`, group: group, index: index, showDisciplineColumn: showDisciplineColumn }))),
                        provided.placeholder))))))));
};
