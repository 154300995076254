import { stringify } from 'qs';
import { Api } from '@campus/auth';
import { PaginationModel, Response } from '@campus/commons';
import DidaticSequence from '../models/DidaticSequence';
import PedagogicalPlanning from '../models/PedagogicalPlanning';
import PedagogicalPlanningGroup from '../models/PedagogicalPlanningGroup';
import PedagogicalPlanningDiary from '../models/pedagogicalPlanningDiary';
import { handleApiCallError } from './common';
import AreaSkillManagement from '../models/AreaSkillManagement';
import { calculateNumberOfPages } from './utils/pagination';
const baseEndpointsPath = '/pedagogicalplanning';
const endpoints = {
    add: { areaSkill: baseEndpointsPath },
    update: { planning: baseEndpointsPath },
    get: {
        planning: baseEndpointsPath + '/byid',
        plannings: baseEndpointsPath + '/complete',
        diary: baseEndpointsPath + '/diary',
        areaSkillManagement: baseEndpointsPath + '/management/areaSkill',
        classReport: '/pedagogicalplanningreport/class-report',
        groupActivities({ pedagogicalPlanningId }) {
            return `${baseEndpointsPath}/${pedagogicalPlanningId}/group-activities`;
        }
    },
    reorderGroupActivities({ pedagogicalPlanningId }) {
        return `${baseEndpointsPath}/${pedagogicalPlanningId}/reorder-group-activities`;
    }
};
export async function getPlanningById(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.get.planning, {
            signal: params.abortSignal,
            params: { Id: params.id }
        });
        const content = PedagogicalPlanning.fromJson(response.data);
        return Response.fromData(content, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function addPlanning(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.post(endpoints.add.areaSkill, {
            GradeId: params.gradeId,
            SegmentId: params.segmentId,
            TeacherId: params.teacherId,
            AreaSkillId: params.areaSkillId,
            DisciplineId: params.disciplineId,
            PedagogicalPlanningStatusId: params.statusId,
            Order: params.order ?? null,
            Group: params.group ?? null,
            ObservedLags: params.observedLags ?? null,
            TeacherObservation: params.teacherNotes ?? null,
            AnalystObservation: params.analystNotes ?? null,
            SupervisorObservation: params.supervisorNotes ?? null
        }, { signal: params.abortSignal });
        const planning = PedagogicalPlanning.fromJson(response.data);
        return Response.fromData(planning, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function getAllPlanningsGrouped(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.get.plannings, {
            signal: params?.abortSignal,
            params: {
                GradeId: params.gradeId,
                SegmentId: params.segmentId,
                DisciplineId: params.disciplineId,
                Planning: !params.getJustNotBeingPlanned
            }
        });
        const content = response.data.map((group) => {
            return PedagogicalPlanningGroup.fromJson(group);
        });
        return Response.fromData(content, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function updatePlanning(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpointWithId = `${endpoints.update.planning}/${params.id}`;
        const response = await axiosInstance.put(endpointWithId, {
            Order: params.order ?? null,
            Group: params.group ?? null,
            SemesterId: params.stageId,
            PedagogicalPlanningStatusId: params.statusId
        }, { signal: params.abortSignal });
        return Response.fromData(null, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function deletePlanning(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpointWithId = `${endpoints.update.planning}/${params.planning.id}`;
        const response = await axiosInstance.delete(endpointWithId, {
            signal: params.abortSignal
        });
        return Response.fromData(null, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function getDataToDiary(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.get.diary, {
            params: {
                SegmentId: params.segmentId,
                GradeId: params.gradeId,
                DisciplineId: params.disciplineId,
                ClassId: params.classId
            }
        });
        const dataDiary = PedagogicalPlanningDiary.fromJson(response.data);
        return Response.fromData(dataDiary, response.status);
    }
    catch (error) {
        return Response.fromError(error);
    }
}
export async function getClassReportFileContent(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.get.classReport, {
            params: {
                SegmentId: params.segmentId,
                GradeId: params.gradeId,
                DisciplineIds: params.disciplineIds,
                ClassId: params.classId,
                ClassroomStartDate: params.classroomStartDate ?? null,
                ClassroomEndDate: params.classroomEndDate ?? null,
                OnlyEvaluativeAreaSkills: params.onlyEvaluativeAreaSkills ?? null
            },
            responseType: 'blob',
            paramsSerializer(p) {
                return stringify(p, { arrayFormat: 'indices', skipNulls: true });
            }
        });
        return Response.fromData(response.data, response.status);
    }
    catch (error) {
        return Response.fromError(error);
    }
}
export async function getGroupActivities(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = endpoints.get.groupActivities(params);
        const response = await axiosInstance.get(endpoint, {
            signal: params.abortSignal
        });
        const activities = Array.isArray(response.data)
            ? response.data.map((item) => DidaticSequence.fromJson(item))
            : [];
        return Response.fromData(activities, response.status);
    }
    catch (error) {
        return Response.fromError(error);
    }
}
export async function reorderGroupActivities(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = endpoints.reorderGroupActivities(params);
        const response = await axiosInstance.post(endpoint, {
            signal: params.abortSignal
        });
        return Response.fromData(null, response.status);
    }
    catch (error) {
        return Response.fromError(error);
    }
}
export async function getDataAreaSkillManagement(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const skipCount = params.pageIndex * params.pageSize;
        const response = await axiosInstance.get(endpoints.get.areaSkillManagement, {
            signal: params?.abortSignal,
            params: {
                SegmentId: params.segmentId,
                GradeId: params.gradeId,
                DisciplineId: params.disciplineId,
                // ClassId: params.classId,
                MaxResultCount: params.pageSize,
                SkipCount: skipCount
            }
        });
        const result = PaginationModel.fromJson(AreaSkillManagement, response.data, skipCount);
        const numberOfPages = calculateNumberOfPages(result, params.pageSize);
        return Response.fromData({ ...result, numberOfPages }, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
