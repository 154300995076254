import styled from 'styled-components';
export const Container = styled.div `
  flex: 1;

  display: grid;

  overflow: hidden;

  margin-bottom: 13px;
  padding-inline: 17px;
`;
export const ScrollableArea = styled.div `
  overflow: auto;
  align-self: stretch;
  scroll-behavior: smooth;
`;
export const Table = styled.div.attrs((props) => ({
    className: props.$showDisciplineColumn ? 'with-discipline-column' : ''
})) `
  display: grid;
  row-gap: 10px;

  --columns: 47px 290px 227px 217px minmax(369px, 1fr) 348px;

  &.with-discipline-column {
    --columns: 190px 47px 290px 227px 217px minmax(369px, 1fr) 348px;
  }
`;
export const TableHeader = styled.div.attrs((props) => ({
    className: props.$showDisciplineColumn ? 'with-discipline-column' : ''
})) `
  z-index: 5;

  height: 32px;

  background: ${({ theme }) => theme.colors.gray50};

  position: sticky;
  top: 0;
  left: 0;

  border-style: solid;
  border-width: 2px 0;
  border-color: ${({ theme }) => theme.colors.gray300};

  display: grid;
  grid-template-columns: var(--columns);

  > div {
    display: grid;
    place-content: center;

    border: inherit;
    border-width: 0 1px 0 0;

    color: ${({ theme }) => theme.colors.gray600};
    background: ${({ theme }) => theme.colors.gray50};

    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    white-space: nowrap;
  }

  > div:last-child {
    border-right-width: 0;
  }

  > div:nth-child(1),
  > div:nth-child(2) {
    z-index: 1;
    position: sticky;
  }

  > div:nth-child(1) {
    left: 0;
  }

  > div:nth-child(2) {
    left: 47px;
  }

  &.with-discipline-column {
    > div:nth-child(2) {
      left: 190px;
    }

    > div:nth-child(3) {
      z-index: 1;
      position: sticky;
      left: calc(190px + 47px);
    }
  }
`;
export const TableBody = styled.div `
  display: grid;
  row-gap: 20px;
`;
