import styled from 'styled-components';
export const Container = styled.div `
  padding: 9px;

  display: flex;
  row-gap: 9px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
export const NoComments = styled.p `
  align-self: center;

  margin: 5px 0 0 0;

  font-size: 12px;
  font-weight: 600;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray300};
`;
